<script>
export default {
    data() {
        return {
            email: '',
            password: '',
            errors: [],
        }
    },

    props() {

    },

    methods: {
        getUserData() {
            return {
                email: this.email,
                password: this.password
            }
        },
        emptyUserData() {
            this.email = '';
            this.$refs.testemail.blur()
            this.password= '';
            this.$refs.testpass.blur()
            this.$refs.testform.reset()
        },

        errorMessages(type) {
            const message = {
                // WrongEmail: "*Cet email n'est pas valide",
                Required: "*Adresse e-mail et mot de passe requis",
                WrongPassword: "*Les mots de passe ne correspondent pas!",
                PasswordInvalid: "*Le mot de passe doit contenir au moins 12 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.",
            }
            return message[type];
        },


        validateForm() {
            this.errors = [];
            // if (!this.email) {
            //     this.errors.push(this.errorMessages('EmailExist'));
            // }
            if (!this.password && !this.email) {
                this.errors.push(this.errorMessages('Required'));
            }

            if (this.email && !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}/.test(this.password)) {
                this.errors.push(this.errorMessages('PasswordInvalid'));
            }
            if (this.errors.length) {
                this.$emit('error', this.errors);
                return false;

            }
            return true;
        }
    }
}


</script>

<template>
    <div v-if="errors.length" class="error_messages">
        <p v-for="(error, index) in errors" :key="index">{{ error }}</p>
    </div>
    <form ref="testform" class="form" @submit.prevent>
        <input ref="testemail" v-model="email" type="email" id="email" name="email" placeholder="Adresse email" required />
        <input ref="testpass" v-model="password" type="password" id="password" name="password" placeholder="Mot de passe" required />
    </form>

</template>

<style scoped>
.form {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}

input {
    width: 100%;
    height: 53px;
    background-color: #ECEFF4;
    border: none;
    border-radius: 8px;

    gap: 16px;
    align-self: stretch;
    padding-left: 10px;

}

input::placeholder {
    color: #8d93a1;
}
input:focus-visible{
    outline:2px solid #99A9BB;
}
.error_messages {
    color: red;
    font-family: 'poppins', sans-serif;
    font-size: 12px;
    width: 54%;
    text-align:justify;

}
</style>