// in main.ts
import '@fortawesome/fontawesome-free/css/all.css';

import { createApp } from 'vue'
// import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'



const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon)
// app.use(createPinia())
app.use(router)

app.mount('#app')