<script>
import NavButton from './NavButton.vue';
export default {

    components: {

        NavButton,
    },
    props: {
        optionName: {
            type: String,
            default: ","
        },
        options: {
            type: Array,
            required: true,
        },
        style: {
            type: Object,
            default: () => ({
                message: '',
                icon: [''],
                fontSize: '16px',
                color: 'black',
                fontWeight: 'bold',
                backgroundColor: 'transparent',
            }),
        },

        label: {
            type: String,
            default: "",
        }
    },


    data() {
        return {
            isDropDownMenuVisible: false,
        }
    },

    methods: {
        toggleDropdown() {
            this.isDropDownMenuVisible = !this.isDropDownMenuVisible;
        }
    }
}
</script>

<template>
    <div class="dropDownMenu">
        <div v-if="label" class="label">{{ label }}</div>

        <NavButton :message="style.message" :icon="style.icon" :width="200" :style="{
            fontSize: style.fontSize,
            color: style.color,
            fontWeight: style.fontWeight,
            backgroundColor: style.backgroundColor
        }" @click="toggleDropdown" />


        <ul v-if="isDropDownMenuVisible" class="dropDownMenuOptions">
            <li v-for="(option, index) in options" :key="index">
                <a href="#" :style="{ color: option.color || 'black' }">{{ option.text || option }}</a>
            </li>
        </ul>
    </div>
</template>



<style scoped>
.dropDownMenu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
}

.label {
    font-size: 14px;
    color: black;
    margin-bottom: 5px;
}

.dropDownMenuContainer {
    width: 100%;
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
}

.dropDownMenuOptions {
    width: 200px;
    list-style-type: none;
    margin-top: 10px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: white;
    border-radius: 5px;
}

.dropDownMenuOptions li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
}

.dropDownMenuOptions li:hover {
    background-color: #ddd;
}

a {
    text-decoration: none;
    color: black;
}

.fa {
    color: black;
    font-size: 20px;
    margin-left: 8px;
}
</style>
