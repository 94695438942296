<script>
import IconComponent from './IconComponent'


export default {
    components: {
        IconComponent
    },

    props: {
        devisId: {
            type: Number,
            required: true
        },
        isMenuVisible: {
            type: Boolean,
            required: true
        },
        menuId: {
            type: Number,
            required: true
        },


    },

    methods: {
        showMenu() {
            this.$emit('toggle-menu', this.menuId);
        },
        closeMenu() {
            this.$emit('toggle-menu', this.menuId);
        },

    }
}
</script>
<template>
    <div class="menu">
        <font-awesome-icon :icon="['fas', 'ellipsis-h']"@click="showMenu" />

        <div v-if="isMenuVisible" class="dropdown-menu">
            <ul>
                <li @click="sendEmail">
                    <font-awesome-icon :icon="['fas', 'paper-plane']" />
                                        <span class="actionName">Envoyer par Email</span>
                </li>

                <li @click="downloadPDF">
                    <font-awesome-icon :icon="['fas', 'file-pdf']" />
                    <span class="actionName"> Télécharger PDF
                    </span>
                </li>
                <li @click="cloneDevis">
                    <font-awesome-icon :icon="['fas', 'clone']" />
                    <span class="actionName"> Cloner
                    </span>

                </li>
                <li @click="editDevis">
                    <font-awesome-icon :icon="['fas', 'pen']" />
                    <span class="actionName"> Modifier
                    </span>

                </li>
                <li @click="deleteDevis">
                    <font-awesome-icon :icon="['fas', 'trash-can']" />
                    <span class="actionName"> Supprimer

                    </span>
                </li>
            </ul>
        </div>
    </div>

</template>



<style scoped>
.menu {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    right: 1px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: -8px -1px 30px 0px rgba(122, 119, 119, 0.20);
    width: 200px;
    height: 250px;
    line-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    z-index: 3;
}
svg{
    width: 15px;
    height: 15px;
}
li {
    display: flex;
    align-items: center;
}

.actionName {
    font-size: 14px;
    margin-left: 10px;  
}
</style>