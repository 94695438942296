<script>
export default {

}
</script>


<template>
    <div class="pdfContainer">
        <img class="pdfSvg" src="../../../public/pdf.png" alt="">
    </div>
</template>


<style scoped>
.pdfContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.pdfSvg {
    width: 400px;
}
</style>