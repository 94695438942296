<script>

import InputComponent from './InputComponent.vue'

export default {
    components: {
        InputComponent
    },
    props: {
        inputs: {
            type: Array,
            default: () => []
        },
        toggles: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isToggled: false
        }
    },
    computed: {
        buttonClass() {
            return this.isToggled ? 'toggle-button-on' : 'toggle-button-off'
        },

        icon() {
            return this.isToggled ? 'fa-toggle-off' : 'fa-toggle-on'
        }

    },
    methods: {
        toggleButton() {
            this.isToggled = !this.isToggled;
        }
    }
}
</script>

<template>
    <div class="section-container">
        <div v-for="(input, index) in inputs" :key="index">
            <InputComponent :label="input.label" :placeholder="input.placeholder" :icon="input.icon"
                :style="input.style" :type="input.type" />
        </div>

        <div v-for="(toggle, index) in toggles" :key="index" class="toggle-container">            
            <span class="toggleText">{{ toggle.toggleText }}</span>

            <button @click="toggleButton(index)" :class="buttonClass">
                <div class="toggle-circle">
                </div>
            </button>
        </div>
    </div>
</template>

<style scoped>
.section-container {
    width: 100%;
    background-color: #E4EFF1;
    border-radius: 10px;
    border: 1px solid #dcdcdc;
    padding: 15px;
    margin-bottom: 15px;
}

.toggle-container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    gap: 20px;
}

button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    height: 21px;
    width: 40px;
    border-radius: 10px;
    padding: 5px;
    position: relative;
}
.toggleText{
    font-size: 16px;
    font-weight: bold;
    color: #333;

}

.toggle-button-on {
    background-color: #4CAF50;
}

.toggle-button-off {
    background-color: #898989;
}

.toggle-circle {
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: white;
    transition: transform 0.3s;
    left: 5px;
}

.toggle-button-on .toggle-circle {
    transform: translateX(15px);
}

.toggle-icon {
    font-size: 15px;
}

span {
    font-size: 14px;
    color: #333;
}
</style>