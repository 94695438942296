<script>
export default {
    props: {
        Title: String,
        Text: String,
        a: String,
        link: {
            type: String,
            required: true,
        }
    },

    methods: {
        handleClick() {
            this.$emit('submit')
            console.log('Button clicked!');

        },
    }
}
</script>

<template>
    <div class="btnContainer">
        <button class="btn" type="submit" value="S'inscrire" @click="handleClick">{{ Title }}</button>
        <p>{{ Text }} <router-link :to="link">{{ a }}</router-link></p>

    </div>
</template>



<style scoped>
.btnContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.btn {
    width: 515px;
    height: 53px;
    font-size: 18px;
    margin-left: 10px;
    background-color: #3065ea;
    color: white;
    border: none;
    border-radius: 8px;
    margin-bottom: 20px;
}

.btnContainer p {
    color: #8d93a1;
    font-size: 12px;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.24px;
    margin-right: 205px;

}

a {
    color: #0e3ea3;
    text-decoration: none;
    font-weight: semibold;
    font-size: 12px;

}
</style>