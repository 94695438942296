<script>
    export default {
        
    }
</script>

<template>
    <div class="checkbox">
            <input type="checkbox" id="checkbox" v-model="checked">
            <label for="checkbox">Se souvenir de moi</label>
            <p><a href="#">Mot de passe oublié ?</a></p>
        </div>
</template>



<style scoped>
input, label, p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    
}
.checkbox {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
}

label{
    color: #8D93A1;
    font-weight: normal;

}
a {
    color: #0e3ea3;
    text-decoration: none;
    font-weight:semibold;
    margin-left: 225px;
    
}
</style>