<script>
import NavButton from '../HomePage/NavBar/NavButton.vue';
export default {
    components: {
        NavButton,
    }
}
</script>


<template>
    <div class="navContainer">
        <div class="leftSide">
            <NavButton :icon="['fas', 'arrow-left']" :message="'Tous les devis'" :width="200"
                :style="{ color: 'white', fontWeight: 'bold', backgroundColor: 'transparent' }" />
        </div>

        <div class="rightSide">

            <NavButton :icon="['fas', 'refresh']" :width="50"
                :style="{ color: 'white', backgroundColor: 'transparent', fontSize: '18px' }" />
            <NavButton :icon="['fas', 'eye']" :width="50"
                :style="{ color: 'white', backgroundColor: 'transparent', fontSize: '18px' }" />
            <NavButton :message="'Sauvegarder'" :width="150"
                :style="{ color: 'white', fontWeight: 'bold', backgroundColor: '#4E5E5F' }" />
            <NavButton :message="'Finaliser et envoyer'" :width="200"
                :style="{ color: 'white', fontWeight: 'bold', backgroundColor: '#28888B' }" />
        </div>

       

    </div>
</template>


<style scoped>
.navContainer {
    width: 100%;
    height: 100px;
    background-color: #2C2C2C;
    display: flex;
    align-items: center;

}

.leftSide {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
}

.rightSide {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 70%;
    height: 60vh;
    margin-right: 30px;
    gap: 15px;

}




/* .navRightSide>*:last-child {
    margin-right: 0;
}
 */
</style>
