<script >
    
</script>

<template>
    <div class="left_side">
            <h1> 
                <p>Rien ne vaut</p>
                <p>une vie...</p>
                <p>sauf devis !</p>
                
            </h1>
        </div>
</template>

<style scoped>

.left_side {
    width: 50%;
    height: 100vh;
    background: url(../../../public/image.png) ;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}

img {

    width: 100%;
    height: auto;
    object-fit: cover;
    position: absolute;
    
    /* z-index: 1; */
}

h1 p {
    position: relative;
    width: 500px;
    font-size: 65px;
    text-align: justify;
    line-height: 1.7;
    color: white;
    /* z-index: 2; */

}

</style>