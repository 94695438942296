<script>
import LeftSide from '@/components/RegisterLogin/LeftSide.vue';
import LogSignForm from '@/components/RegisterLogin/LogSignForm.vue';
import SubmitButton from '@/components/RegisterLogin/SubmitButton.vue';
import Checkbox from '@/components/RegisterLogin/Checkbox.vue';
import axios from 'axios';

export default {
    components: {
        LeftSide,
        LogSignForm,
        SubmitButton,
        Checkbox
    },

    data() {
        return {
            user: {
                email: '',
                password: '',
                // rememberMe: false,
            },
            errorMessage: '',

        }
    },

    methods: {

        handleForm() {
            this.errorMessage = '';
            const valid = this.$refs.LogSignForm.validateForm();
            if (valid) {
                this.login();

            }
        },
        async login() {
            const userData = this.$refs.LogSignForm.getUserData();

            try {
                const response = await axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/login', userData)
                localStorage.setItem('token', response.data.token);
                this.$router.push('/');
            } catch (error) {
                const errorMessage = error.response.data.message;
                // console.error('Login error:', errorMessage);
                this.errorMessage = errorMessage;
            }
        },
    }


}

</script>

<template>
    <div id="logIn" class="container">
        <LeftSide />
        <div class="right_side">
            <h3>Connectez-vous</h3>

            <div class="error_messages">
                <h4>
                    Entrez votre adresse e-mail et votre mot de passe pour vous connecter
                </h4>
                <p v-if="errorMessage">{{ errorMessage }}</p>
            </div>
            <LogSignForm ref="LogSignForm" />
            <Checkbox />
            <SubmitButton @submit="login" Title="Se connecter" Text="Vous voulez créer un compte ?" a="Inscrivez-vous"
                link="/signin" />
        </div>

    </div>
</template>


<style scoped>
.container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: Poppins;


}

.right_side {
    background-color: white;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

h3 {
    color: #0e3ea3;
    font-family: 'poppins', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.72px;
    margin-right: 185px;
}



.right_side .error_messages {
    color: red;
    font-family: 'poppins', sans-serif;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    width: 60%;

}

.error_messages h4 {
    color: #8d93a1;
    font-weight: normal;
    font-size: 14px;

}

.error_messages p {
    text-align:start;
    margin-left: 28px;

}
</style>