<script>
import DropDownMenu from './DropDownMenu.vue';
import NavButton from './NavButton.vue';

export default {
    components: {
        DropDownMenu,
        NavButton
    },
    data() {
        return {
            isFiltersVisible: false,
            OptionsMenu1: ['Client 1', 'Client 2', 'Client 3'],
            Status: [
                { text: 'SENT', color: 'blue' },
                { text: 'SIGNED', color: 'green' },
                { text: 'DRAFT', color: 'orange' },
            ], OptionsMenu3: ['All time', 'Last 7 Days', 'Last 30 Days', 'This year', 'This year', 'This year', 'This year', 'This year',],
        };
    },

    methods: {
        toggleFilters() {
            this.isFiltersVisible = !this.isFiltersVisible;
        }
    }
}
</script>

<template>
    <div class="filterContainer">
        <NavButton :message="'Filter'" :icon="['fas', 'filter']" :width="80" @click="toggleFilters" />

        <div v-if="isFiltersVisible" class="filterMenus">
            <DropDownMenu :options="OptionsMenu1" label="Client"
                :style="{ message: 'Tous les clients', icon: ['fas', 'chevron-down'], backgroundColor: 'white' }" />

            <DropDownMenu :options="Status" label="Status" :style="{
                message: 'Status', backgroundColor: 'white',
                icon: ['fas', 'chevron-down']
            }" />

            <DropDownMenu :options="OptionsMenu3" label="Date"
                :style="{ message: 'All time', icon: ['fas', 'chevron-down'], backgroundColor: 'white' }" />
        </div>


    </div>
</template>



<style scoped>
.filterContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-around;
}

.filterMenus {
    display: flex;
    justify-content: center;
    padding: 10px;
    gap: 10px;
    width: 650px;
    min-height: 100px;
    margin-top: 10px;
    background-color: whitesmoke;
    border-radius: 10px;
    position: absolute;
    top: 100px;
    right: 510px;

}
</style>