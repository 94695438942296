<script>
import AllSections from '@/components/NewDevis/AllSections.vue'
import PdfDisplay from '@/components/NewDevis/PdfDisplay.vue';
import NewDevisNav from '@/components/NewDevis/NewDevisNav.vue';

export default {
    components: {
        AllSections,
        PdfDisplay,
        NewDevisNav,
    }
}
</script>

<template>
    <div class="newDevisContainer">
        <header class="newDevisNav">
            <NewDevisNav />
        </header>

        <main class="bothSides">
            <section class="leftSide">
                <AllSections />
            </section>
            <section class="rightSide">
                <PdfDisplay />
            </section>
        </main>
    </div>
</template>

<style scoped>

.newDevisContainer {
    height: calc(100vh - 16px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    scrollbar-width: none;
}

.bothSides {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    overflow: hidden;

}

.leftSide {
    width: 45%;
    padding: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 40px;
    scrollbar-width: none;
    
}


.rightSide {
    width: 45%;
    height: 80vh;
    background-color: #E4EFF1;
    border-radius: 10px;
    border: 1px solid #dcdcdc;
    padding: 15px;

}
</style>
