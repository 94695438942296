<script>
import IconComponent from "../IconComponent.vue";

export default {
    components: {
        IconComponent,
    },

    props: {
        message: {
            type: String,
            default: "",
        },
        icon: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        width: {
            type: [String, Number],
            default: 100,
        },
        height: {
            type: [String, Number],
            default: 40,
        },


        style: {
            type: Object,
            default: () => ({
                fontSize:"14px",
                color: "black",
                backgroundColor: "whitesmoke",
                fontWeight: 'normal',
            }),
        },
    },

    computed: {
        buttonStyles() {
            return {
                ...this.style,
                width: this.width + "px", 
                height: this.height + "px", 
            };
        },
    },
};
</script>

<template>
    <div class="btn-container">
        
        <button :style="buttonStyles" class="btn">
            <font-awesome-icon :icon="icon" />
            <span v-if="message" class="btn-text">{{ message }}</span>
            <span v-if="placeholder" class="btn-placeholder">{{ placeholder }}</span>
        </button>
    </div>
</template>

<style scoped>
.btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    position: relative; 
}

.btn {
    display: inline-flex;
    align-items: center;
    width: 200px;
    height: 50px;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
}

.btn-text {
    margin-left: 8px;
}

.btn-placeholder {
    font-size: 14px;
    color: grey;
    margin-left: 8px;
}
</style>
