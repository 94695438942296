<script>

export default {

    props: {

        icon: {
            type: Array,
            required: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        type: {
            type: String,
        },

        style: {
            type: Object,
            default: () => ({
                fontSize: '16px',
                color: 'black',
            }),

        }

    }

}
</script>


<template>
    <div class="input-container">
        <label v-if="label" class="input-label">{{ label }}</label>
        <font-awesome-icon :icon="icon" class="input-icon" /> 
        <component  
         :is="type === 'textarea' ? 'textarea' : 'input'"
            class="input"
            :type="type"
            :placeholder="placeholder"
            v-model="inputText"
            :style="{ fontSize: style.fontSize, color: style.color }"
            rows="3" />
    </div>

</template>


<style scoped>
.input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 15px;
}

.input-label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.input {
    width: 57%;
    min-height: 30px; 
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #dcdcdc;
    background: #fff;
    font-size: 14px;
    transition: border-color 0.3s ease
}
.input:focus, textarea:focus {
    border-color: #003E5A;
    outline: none;
}

.input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    color: #666;
    pointer-events: none;
}
</style>
