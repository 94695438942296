<script>
import DropDownMenu from './DropDownMenu.vue';
import NavButton from './NavButton.vue';

export default {
    components: {
        DropDownMenu,
        NavButton,

    },

    data() {
        return {
            isFiltersVisible: true,
            Status: [
                { text: 'SENT', color: 'blue' },
                { text: 'SIGNED', color: 'green' },
                { text: 'DRAFT', color: 'orange' },
            ]
        };
    },
    methods: {
        toggleStatus() {
            this.isFiltersVisible = !this.isFiltersVisible;
        }
    }
}
</script>

<template>
        <div v-if="isFiltersVisible" class="status">
            <DropDownMenu :style="{
                message: 'Tous les devis',
                icon: ['fas', 'chevron-down'],
                fontSize: '16px',
                color: 'black',
                fontWeight: 'bold',
                backgroundColor: 'whitesmoke',
            }" :options="Status" />
        </div>


</template>



<style scoped>


.status {
    border-radius: 10px;
    position: absolute;
    cursor: pointer;
    top: 50px;


}
</style>