<script>
import SectionComponent from './SectionComponent.vue';
export default {
    components: {
        SectionComponent
    },

    data() {
        return {
            sections: [

                {
                    inputs: [
                        { label: 'Titre', placeholder: 'Nouveau Devis', icon: ['fas', 'pen'] },
                        { label: 'Sous-titre', placeholder: 'Sous-titre', icon: ['fas', 'pen'] }
                    ],
                    toggles: [
                        { toggleText: 'Aficher le numéro du devis' }
                    ],

                },

                {
                    inputs: [
                        { label: 'Client', placeholder: 'Sélectionnez un nouveau client', icon: ['fas', 'chevron-down'] },
                        { label: 'Date de validité', type: 'date' },
                        { label: 'Présentation', icon: ['fas', 'pen'], type: 'textarea' },
                        { label: 'Objectifs', placeholder: 'Décrivez objectif', icon: ['fas', 'pen'], type: 'textarea' },

                    ]

                },
                {
                    inputs: [
                        { label: 'Equipe', placeholder: 'Nouveau Devis', icon: ['fas', 'chevron-down'], style: { fontSize: '16px', color: 'black' } },
                        { label: 'Livrables', placeholder: 'Ajoutez une description', icon: ['fas', 'chevron-down'] }
                    ],
                    toggles: [
                        { toggleText: 'Hebergement & maintenance' },
                        { toggleText: 'Aficher un tableau de tarification final' },
                    ]


                },
                {
                    inputs: [
                        { label: 'Taux journalier moyen', style: { fontSize: '18px' }, type: 'number' },
                    ],
                    toggles: [{ toggleText: 'Aficher le RIB' }],

                },


            ]
        };
    },
}
</script>


<template>
    <div>
        <div v-for="(section, index) in sections" :key="index">
            <SectionComponent :inputs="section.inputs" :toggles="section.toggles" />
        </div>
    </div>
</template>


<style scoped></style>