<script>
import axios from "axios";
import LogSignForm from "@/components/RegisterLogin/LogSignForm.vue";
import SubmitButton from "@/components/RegisterLogin/SubmitButton.vue";
import LeftSide from "@/components/RegisterLogin/LeftSide.vue";

export default {
    components: {
        LogSignForm,
        SubmitButton,
        LeftSide,
    },

    data() {
        return {
            errorMessage: '',
        }
    },
    methods: {
        handleForm() {
            this.errorMessage = '';
            const valid = this.$refs.LogSignForm.validateForm();
            if (valid) {
                this.addUser();
            }
        },
        async addUser() {
            const userData = this.$refs.LogSignForm.getUserData();
            try {
                const response = await axios.post(process.env.VUE_APP_BACKEND_URL +
                    "/users/newUser",
                    userData
                );
                // console.log("Utilisateur ajouté:", response.data);
                this.$router.push('/login');
            } catch (error) {
                console.log(error)
                const errorMessage = error.response.data.message;

                console.error("Erreur lors de la création de l'utilisateur :", errorMessage);
                this.errorMessage = errorMessage;
            }
            finally {
                this.$refs.LogSignForm.emptyUserData()
            }
        },
        handleValidationErrors(errors) {
            console.error("Erreurs de validation :", errors);

        },
    },
};
</script>

<template>
    <div id="signIn" class="container">
        <LeftSide />
        <div class="right_side">
            <h3>Inscrivez-vous</h3>

            <div class="error_messages">
                <h4>
                    Entrez votre adresse e-mail et votre mot de passe pour vous inscrire
                </h4>
                <p v-if="errorMessage">{{ errorMessage }}</p>
            </div>

            <LogSignForm @error="handleValidationErrors" ref="LogSignForm" />
            <div class="btnContainer">
                <SubmitButton @submit="handleForm" Title="M'inscrire" Text="Vous avez déjà un compte ?"
                    a="Connectez-vous " link="/login" />
            </div>
        </div>
    </div>
</template>

<style scoped>


.container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
}

.right_side {
    background-color: white;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



h3 {
    color: #0e3ea3;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.72px;
    margin-right: 200px;
}



.right_side .error_messages {
    color: red;
    font-family: 'poppins', sans-serif;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    margin-left: 18px;

    width: 55%;

}

.error_messages h4 {
    color: #8d93a1;
    font-weight: normal;
    font-size: 14px;
    text-align:justify;


}


.error_messages p {
    text-align:start;

}


</style>
