<script>
import NavButton from '@/components/HomePage/NavBar/NavButton.vue';
import Filter from './Filter.vue';
import DevisMenu from './DevisMenu.vue';

export default {
    components: {
        NavButton,
        DevisMenu,
        Filter,


    }, 
    methods: {
        redirectToDevisPage() {
                this.$router.push('/newdevis');
            }
        
    }
}
</script>

<template>
    <nav class="navContainer">
        <div class="navLeftSide">
            <DevisMenu />
        </div>
        <div class="navRightSide">
            <Filter />
            <NavButton :icon="['fas', 'search']" :width="220" :placeholder="'Search'" :style="{
                backgroundColor: 'whitesmoke',
                fontSize:'16px',
                color: '#999',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }" />
            <NavButton :icon="['fas', 'download']" :width="50"
                :style="{ color: 'white', fontWeight: 'bold', backgroundColor: 'black' }" />
            <NavButton :message="'Nouveau devis'" :icon="['fas', 'plus']" :width="160"
                :style="{ color: 'white', fontWeight: 'bold', backgroundColor: 'black' }" @click="redirectToDevisPage" />
            <NavButton :icon="['fas', 'cog']" :width="50" :style="{ backgroundColor:'transparent',fontSize:'18px'}" />

        </div>
    </nav>

</template>

<style scoped>
.navContainer {
    display: flex;
    align-items: center;
    margin-top: 50px;
    width: 80%;
}

.navLeftSide {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navRightSide {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.navRightSide>* {
    margin-right: 10px;
}

.navRightSide>*:last-child {
    margin-right: 0;
}


</style>